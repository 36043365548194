/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    code: "code",
    pre: "pre",
    blockquote: "blockquote",
    h3: "h3",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "integer--floor--square-root",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#integer--floor--square-root",
    "aria-label": "integer  floor  square root permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Integer ( Floor ) square root"), "\n", React.createElement(_components.p, null, "of a whole number and program to find it in Rust Language"), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, "Many times, we have to compute the integer or ", React.createElement(_components.strong, null, "floor value of square root"), " of a given very large number instead of  exact square root."), "\n", React.createElement(_components.p, null, "You may argue that we can find the square root of a number using default ", React.createElement(_components.code, null, "sqrt()"), " method, and then take its floor."), "\n", React.createElement(_components.p, null, "But, there is a catch. ", React.createElement(_components.code, null, "f64"), " type uses some bits to represent the floating digits. So, it reduces the accuracy of square roots of numbers greater than 10", React.createElement("sup", null, "14"), "."), "\n", React.createElement(_components.p, null, "So, if you have to find a floor of square root of. say, 10", React.createElement("sup", null, "18"), ", you will simply get inaccurate answers.\nThis is a frequent cause of failing testcases in many questions, especially if 10 test cases are passing, and 11th gives wrong answer in solution involving square root of number >= 10", React.createElement("sup", null, "15"), ", this might be the issue."), "\n", React.createElement(_components.p, null, "So, in this article, we will see how to find ", React.createElement(_components.strong, null, "Floor of square root"), " of a number in Logarithmic time complexity using binary search in Rust Language."), "\n", React.createElement(_components.h2, {
    id: "naive-approach",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#naive-approach",
    "aria-label": "naive approach permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Naive approach"), "\n", React.createElement(_components.p, null, "Naive Approach would be to linearly check each and every number from 1 till we find a number whose square root is greater than the given number."), "\n", React.createElement(_components.p, null, "This will take ", React.createElement(_components.strong, null, "O( sqrt(n) )"), " time complexity. Function using this approach is"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn square_root(num:usize) -> usize{\n    let mut i = 0;\n\n    // If the number is 0 or 1,\n    if num <=1 {\n        return num;\n    }\n\n    // Increase i, till square of i+1 is less than or equal to number\n    while (i+1)*(i+1) <= num {\n        i+=1;\n    }\n    return i;\n}\n")), "\n", React.createElement(_components.p, null, "Program With driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "\nfn square_root(num:usize) -> usize{\n    let mut i = 0;\n\n    // If the number is 0 or 1,\n    if num <=1 {\n        return num;\n    }\n\n    // Increase i, till square of i+1 is less than or equal to number\n    while (i+1)*(i+1) <= num {\n        i+=1;\n    }\n    return i;\n}\n\n// Driver Code\n\nfn main() {\n    println!(\"{}\", square_root(899));\n    println!(\"{}\", square_root(900));\n    println!(\"{}\", square_root(901));\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "29 ", React.createElement("br"), "\n30 ", React.createElement("br"), "\n30"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( n )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.h2, {
    id: "efficient-binary-search-approach",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#efficient-binary-search-approach",
    "aria-label": "efficient binary search approach permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Efficient Binary Search Approach"), "\n", React.createElement(_components.p, null, "We can optimize the above approach using binary search, and find the integer square root in  ", React.createElement(_components.strong, null, "Logarithmic time"), " instead of Linear time."), "\n", React.createElement(_components.p, null, "Below algorithm can find the integer square root of numbers upto ", React.createElement(_components.strong, null, "10", React.createElement("sup", null, "18")), " in logarithmic time complexity."), "\n", React.createElement(_components.h3, {
    id: "algorithm",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#algorithm",
    "aria-label": "algorithm permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Algorithm"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "If the number is 0 or 1, return that number."), "\n", React.createElement(_components.li, null, "Take starting point ( or low ) to be 1 and ending point ( or high ) to be 1.1×10", React.createElement("sup", null, "9"), " ."), "\n", React.createElement(_components.li, null, "Find the mid-point of high and low. If mid×mid is equal to the number, return the mid."), "\n", React.createElement(_components.li, null, "If mid is less than the number, store the value of the mid, and set the low to mid+1."), "\n", React.createElement(_components.li, null, "Else, Set the high to mid -1."), "\n", React.createElement(_components.li, null, "Repeat steps 3 to 5 till high is not equal to low."), "\n", React.createElement(_components.li, null, "If high is equal to low, return the stored value of mid in step 4."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note :"), " I have taken 1.1×10", React.createElement("sup", null, "9"), " as high because it can find the square root of all the numbers upto  ", React.createElement(_components.strong, null, "10", React.createElement("sup", null, "18")), ", without ", React.createElement(_components.strong, null, "overflowing.")), "\n", React.createElement(_components.h3, {
    id: "function",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#function",
    "aria-label": "function permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Function"), "\n", React.createElement(_components.p, null, "Here is the function using above algorithm"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "\nfn square_root(num:usize) -> usize{\n\n    // If number is less than or equal to 1, return it\n    if num<=1 { return num }\n\n    // Take high and low, mid and ans .\n    let mut low : usize = 1;\n    let mut high : usize = 1_100_000_000;\n\n    let mut mid;\n    let mut ans = 0;\n\n\n    while high>=low {\n        // Right shift by 1 is equivalent to divide\n        mid = (high+low)>>1;\n\n        let mid_square = mid*mid;\n\n        // If mid_square if equal to number, return mid\n        if mid_square == num { return mid }\n\n        // If mid_square is less than number, set ans to mid\n        // And low to mid+1\n        // Else, set high to mid -1\n        if mid_square < num {\n            ans = mid;\n            low = mid+1;\n        }else {\n            high = mid-1;\n        }\n    }\n\n    // In case the given number is not a prefect square,\n    // Return stored ans, because it is floor of square root.\n    return ans;\n}\n")), "\n", React.createElement(_components.p, null, "Use the same driver code."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "29 ", React.createElement("br"), "\n30 ", React.createElement("br"), "\n30"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( log( n ) )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note :"), " Similarly, you can use the same program for ", React.createElement(_components.strong, null, "Cube Root"), " and other higher degree roots."), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "Many times, we have to compute the integer or ", React.createElement(_components.strong, null, "floor value of square root"), " of a given very large number instead of  exact square root."), "\n", React.createElement(_components.p, null, "The default ", React.createElement(_components.code, null, "sqrt()"), " method might become inaccurate for such large numbers. Hence, we have to use binary search method to compute such large square roots."), "\n", React.createElement(_components.p, null, "In this article, we saw how to find the integer or floor square root of very large number in logarithmic time complexity using binary search in Rust Language."), "\n", React.createElement(_components.p, null, "Here is the optimized function for easy access"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn square_root(num:usize) -> usize{\n    if num<=1 { return num }\n    let mut l : usize = 1;\n    let mut h : usize = 1_100_000_000;\n    let mut m;\n    let mut ans = 0;\n    while h>=l {\n        m = (h+l)>>1;\n        let m_s = m*m;\n        if m_s == num { return m }\n        if m_s < num { ans = m ; l = m+1; }\n        else { h = m-1; }\n    }\n    return ans;\n}\n")), "\n", React.createElement(_components.p, null, "I suggest you to include it in your template."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Integer ( Floor value ) of Square Root of very large number using Binary Search - Rust Programming",
    description: "Many times, we have to compute the integer or floor value of square root. In this article, we will see how to find the integer or floor square root of very large number in logarithmic time complexity using binary search in Rust Language"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
